// 1. Your custom variables and variable overwrites.
@import "../custom/variables.scss";
// … import all

// 2. Import default variables and available mixins.
@import "../variables.scss";
@import "../mixins.scss";

// 3. Your custom mixin overwrites.
// @import "theme/accordion-mixins.scss";
// @import "theme/alert-mixins.scss";
// @import "theme/align-mixins.scss";
// … import all

// 4. Import UIkit
@import "../uikit-custom.scss";